<template>
  <div class="main-container"> 
    <!-- 数据统计 -->
    <el-card class="mb-16"> 
      <p class="title">统计数据</p>
      <div class="dataStatistics">
        <div v-for="item in statisticsList" :key="item.title" class="item" @click="toProject(item)" :style="{ backgroundColor: item.backgroundColor }">
            <div class="item-left">
              <p>{{item.title}}</p>
              <p class="count">{{item.count}}</p>
            </div>
            <el-image style="width: 32px; height: 32px" :src="require(`@/assets/home/${item.icon}.png`)"></el-image>
        </div>
      </div>
    </el-card>
    <!-- 当月统计 -->
    <el-card class="mb-16">
      <p class="title">新增数据</p>
      <div class="dataStatistics">
        <div v-for="item in statisticsNewList" :key="item.title" class="itemNew" @click="toProject(item, true)" :style="{ backgroundColor: item.backgroundColor }">
            <div class="itemNew-left">
              <p>{{item.title}}</p>
              <p class="count">{{item.count}}</p>
            </div>
            <el-image style="width: 50px; height: 50px" :src="require(`@/assets/home/${item.icon}.png`)"></el-image>
        </div>
      </div>
    </el-card>
    <!-- 折线图 -->
    <el-card>
      <div ref="lineChart" style="width: 100%; height: 400px;"></div>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        name: 1,
        statisticsList:[
          {
            icon:'home1',
            title:'项目BP',
            count: 0,
            backgroundColor:'#409eff',
            attachmentType:'bpCount',
            attachmentTypeKey: 1
          },
          {
            icon:'home2',
            title:'访谈纪要',
            count: 0,
            backgroundColor:'#add8e6',
            attachmentType:'interviewSummaryCount',
            attachmentTypeKey: 2
          },
          {
            icon:'home3',
            title:'立项报告',
            count: 0,
            backgroundColor:'#67c23a',
            attachmentType:'projectProposalReportCount',
            attachmentTypeKey: 3
          },
          {
            icon:'home4',
            title:'尽调报告',
            count: 0,
            backgroundColor:'#e6a23c',
            attachmentType:'dueDiligenceReportCount',
            attachmentTypeKey: 4
          },
          {
            icon:'home5',
            title:'投决报告',
            count: 0,
            backgroundColor:'#f0e68c',
            attachmentType:'investmentDecisionReportCount',
            attachmentTypeKey: 5
          },
          {
            icon:'home6',
            title:'联系人信息',
            count: 0,
            backgroundColor:'#dda0dd',
            attachmentType:'enterpriseConcatCount',
            attachmentTypeKey: 6
          },
        ],
        statisticsNewList:[
          {
            icon:'home11',
            title:'项目BP',
            count: 0,
            backgroundColor:'#e0e4e5',
            attachmentType:'bpCount',
            attachmentTypeKey: 1
          },
          {
            icon:'home22',
            title:'访谈纪要',
            count: 0,
            backgroundColor:'#e0e4e5',
            attachmentType:'interviewSummaryCount',
            attachmentTypeKey: 2
          },
          {
            icon:'home33',
            title:'立项报告',
            count: 0,
            backgroundColor:'#e0e4e5',
            attachmentType:'projectProposalReportCount',
            attachmentTypeKey: 3
          },
          {
            icon:'home44',
            title:'尽调报告',
            count: 0,
            backgroundColor:'#e0e4e5',
            attachmentType:'dueDiligenceReportCount',
            attachmentTypeKey: 4
          },
          {
            icon:'home55',
            title:'投决报告',
            count: 0,
            backgroundColor:'#e0e4e5',
            attachmentType:'investmentDecisionReportCount',
            attachmentTypeKey: 5
          },
          {
            icon:'home66',
            title:'联系人信息',
            count: 0,
            backgroundColor:'#e0e4e5',
            attachmentType:'enterpriseConcatCount',
            attachmentTypeKey: 6
          },
        ],
        downloadDateCount: [],
        uploadDateCount: [],
        userCount: [],
        myChart: null
      }
    },
    mounted () {
      // this.initLineChart();
      this.getStatistics()
      this.getProjectStatistics()
      // console.log(this.$router,"this.$router-------")
      window.addEventListener('resize', () => {
        this.myChart?.resize()
      })
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('resize', this.myChart?.resize)
      })

    },
    methods: {
      getProjectStatistics() {
        Promise.all([
          this.$http.Post(this.$api.projectStatistics, {
            fileStatus: 2
          }),
          this.$http.Post(this.$api.projectStatistics, {
            fileStatus: 2,
            queryStartTime: this.$moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            queryEndTime: this.$moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
          })
        ]).then(([ { data: all = {}}, { data: today = {} } ]) => {
          Object.keys(all).forEach(key => {
            const active = this.statisticsList.find(i => i.attachmentType === key)
            if (active) {
              active.count = all[key] || 0
            }
          })
          Object.keys(today).forEach(key => {
            const active = this.statisticsNewList.find(i => i.attachmentType === key)
            if (active) {
              active.count = today[key] || 0
            }
          })
        })
      },
      getStatistics() {
        Promise.all([
          this.$http.Post(this.$api.downloadUploadStatistics, {
            queryStartTime: this.$moment().clone().subtract(30, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            queryEndTime: this.$moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
          }),
          this.$http.Post(this.$api.userStatistics, {
            queryStartTime: this.$moment().clone().subtract(30, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            queryEndTime: this.$moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
          })
        ]).then(([ downUpData = {}, userData ]) => {
          const { data: { downloadDateCount = [], uploadDateCount = [] } = {} } = downUpData
          const { data: { userCount } = {} } = userData
          this.downloadDateCount = downloadDateCount
          this.uploadDateCount = uploadDateCount
          this.userCount = userCount
          this.initLineChart()
        })
      },
      toProject(item, todaya) {
        const params = todaya ? {
          queryStartTime: this.$moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
          queryEndTime: this.$moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
        } : {}
        console.log('params====>', params)
        this.$router.push(
          {
            path:'/project/attachmentList',
            query:{attachmentType:item.attachmentTypeKey, ...params}
          }
        )
      },
      initLineChart() {
        const chartDom = this.$refs.lineChart;
        this.myChart = this.$echarts.init(chartDom);
        const option = {
          title: {
            text: '统计'
          },
          tooltip: {
            trigger: 'axis'
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          legend: {
            top: '5%',
            left: 'center'
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            data: this.uploadDateCount.map(i => i.dateStr)
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '上传数',
              data: this.uploadDateCount.map(i => i.count),
              type: 'line',
              smooth: true
            },
            {
              name: '下载数',
              data: this.downloadDateCount.map(i => i.count),
              type: 'line',
              smooth: true
            },
            {
              name: '新增用户数',
              data: this.userCount.map(i => i.count),
              type: 'line',
              smooth: true
            }
          ]
        };
  
        this.myChart.setOption(option);
      }
    },
  }
</script>

<style lang="less" scoped>

  .main-container {
    .title {
      color: #333;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 24px;
    }
    .dataStatistics{
      display: flex;
      .item {
        width: calc(100%/6);
        height: 100px;
        margin-right: 10px;
        border-radius: 4px;
        color: white;
        padding: 24px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .item-left {
          margin-right: 16px;
          p {
          margin: 0px;
          }
          .count {
            font-size: 24px;
          }
        }
      }
      .item:last-child {
        margin-right: 0px;
      }
      .itemNew {
        width: calc(100%/6);
        height: 100px;
        margin-right: 10px;
        border-radius: 4px;
        color: #333333;
        padding: 24px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .itemNew-left {
          margin-right: 16px;
          p {
          margin: 0px;
          }
          .count {
            color: #999999;
            font-size: 24px;
          }
        }
      }
      .itemNew:last-child {
        margin-right: 0px;
      }
    }
  }
  .mb-16{
    margin-bottom: 16px;
  }

</style>